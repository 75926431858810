import imageNotLoading from "assets/images/image-loading-error.png";
import { default as BrainScratcherUserImage } from "components/BsUserImage";
import LikeButton from "components/likeButton";
import { DrawerContext } from "context/drawerContext";
import { BrainScratcherDetails } from "interfaces";
import { useContext } from "react";
import { getRelativeTime, openAppOrBrowser } from "Utils";
import styles from './BrainScratcherCard.module.css';

/* eslint-disable react/react-in-jsx-scope */
const BrainScratcherCard = ({ data, bsId }: { data: BrainScratcherDetails, bsId?: string}) => {
    const { setIsOpenDrawer } = useContext(DrawerContext);
    console.log(data);
    return (
        <div className={`w-full lg:w-[60%] h-[30%] flex-grow rounded-[22px] transition-all duration-300 ease-in-out bg-[#101114] bg-gradient-to-bl from-[rgba(99,134,203,0.1)] via-[rgba(238,249,255,0.05)] to-[rgba(96,236,251,0.1)]`}>
            <div className='text-2xl font-bold text-white bg-[rgba(16,17,20,1)] lg:p-[1.9px] p-[1.2px] rounded-[22px] transition-all duration-300 ease-in-out bg-gradient-to-br from-[rgba(99,134,203,1)] via-[rgba(255,255,255,0.1)] to-[rgba(96,236,251,0.9)]'>
                <div className="flex flex-col w-full h-full bg-[rgba(16,17,20,1)] text-white rounded-[22px] p-4 items-start lg:gap-5 gap-2 shadow-[0px_10px_1px_rgba(0,0,0,_0.1),_0_10px_20px_rgba(1,1,1,_0.2)]">
                    <div className="flex flex-row gap-4 items-center justify-between w-full">
                        <div className="flex flex-row lg:gap-4 gap-2 items-center">
                            <BrainScratcherUserImage className="lg:w-[72px] lg:h-[72px] w-[40px] h-[40px]" imageUrl={data.createdByImage} borderRadius="10px" />
                            <div className='flex flex-col gap-1'>
                                <div className=' text-white lg:text-2xl text-base text-left font-medium first-letter:uppercase font-sans'>
                                    {data.createdByName.substring(0, 35)} {data.createdByName.length >= 35 && '...'}
                                </div>
                                <div className='text-neutral-400 lg:text-lg text-sm font-normal text-left font-sans'>
                                    {data.createdByHeadline.substring(0, 35)} {data.createdByHeadline.length >= 35 && '...'}
                                </div>
                            </div>
                        </div>
                        <div className='lg:text-lg text-xs text-white/60 font-normal'>
                            {getRelativeTime(data.createdAt)}
                        </div>
                    </div>
                    <div className='font-sans text-white font-medium first-letter:capitalize lg:text-[28px] text-xl text-left'>
                        {data.title}
                    </div>
                    <div className='text-neutral-300 lg:text-[20px] text-base font-normal text-left font-sans'>
                        {data.message}
                    </div>

                    <div className="relative w-full">
                        <div className={`carousel flex overflow-x-scroll snap-x snap-mandatory gap-4 ${styles['no-scrollbar']}`}>
                            {data.media.map((mediaItem, index) => (
                                <div key={index} className="relative flex-shrink-0 w-full snap-center">
                                    {mediaItem.path ? (
                                        <>
                                            <img
                                                src={mediaItem.path}
                                                alt={mediaItem.id || "Media Image"}
                                                className="w-full lg:h-[15rem] h-[25rem] object-cover rounded-xl"
                                                onError={(e) => {
                                                    e.currentTarget.src = imageNotLoading;
                                                    e.currentTarget.className = "w-[15rem] lg:h-[15rem] h-[25rem] object-contain py-10 m-auto bg-[rgba(51,53,56,1)] rounded-xl ";
                                                }}
                                            />
                                            <div className="absolute top-2 right-2 bg-neutral-900 bg-black/60 bg-opacity-50 text-neutral-100 text-sm px-2 py-1 rounded-2xl">
                                                {index + 1}/{data.media.length}
                                            </div>
                                        </>
                                    ) : (
                                        <p>No image available</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:mx-[20px] lg:my-[20px] mx-[10px] my-[10px]" onClick={() => {
                if (window.innerWidth >= 1024) {
                    setIsOpenDrawer(true);
                } else {
                    openAppOrBrowser({ bsId: bsId });
                }
            }}>
                <LikeButton likes={data.likeCount} />
            </div>
        </div>
    );
}

export default BrainScratcherCard;