import React, { ReactNode, createContext, useState } from 'react';

interface BsContextProps {
    bsId: string;
    setBsId: (code: string) => void;
}

export const BsContext = createContext<BsContextProps>({
    bsId: '',
    setBsId: () => { },
});

export const BsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [bsId, setBsId] = useState<string>('');

    return (
        <BsContext.Provider value={{ bsId, setBsId }}>
            {children}
        </BsContext.Provider>
    );
};
