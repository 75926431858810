/* eslint-disable react/react-in-jsx-scope */
import InterestPill from "components/InterestPill";
import { DrawerContext } from "context/drawerContext";
import { UserContext } from "context/userCodeContext";
import { useContext } from "react";
import { openAppOrBrowser } from "Utils";

const UserInterestsCard = () => {
    const { user } = useContext(UserContext);
    const interests = user?.interests || [];
    const displayedInterests = interests.slice(0, 10);
    const remainingInterestsCount = interests.length > 10 ? interests.length - 10 : 0;
    const { setIsOpenDrawer } = useContext(DrawerContext);

    return (
        <div className={`lg:bg-[rgba(26,29,31,1)] bg-[rgba(16,17,20,1)] block p-6 shadow break-words text-left mt-[16px] mb-6 md:mb-0 rounded-xl`}>
            <h5 className="lg:text-xl text-lg font-semibold tracking-tight text-gray-900text-white font-sans mb-5">Interests</h5>
            <div className="flex flex-wrap gap-2">
                {interests.length > 0 ? <>
                    {displayedInterests.map((interest, index) => (
                        <InterestPill key={index} interest={interest} />
                    ))}
                    {remainingInterestsCount > 0 && (
                        <p className="font-normal text-[rgba(96,236,251,1)] font-sans leading-relaxed flex items-center" onClick={() => { if (window.innerWidth >= 1024) {
                            setIsOpenDrawer(true);
                        } else {
                            openAppOrBrowser({ userId: user?.id });
                        }}}>+{remainingInterestsCount} More</p>
                    )}</> : <p className="font-normaltext-neutral-500 font-sans leading-relaxed">No Interests</p>}
            </div>
        </div>
    );
}

export default UserInterestsCard;
