/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
import UserBrainScratcherCard from 'components/UserBrainScratcherCard';
import { DrawerContext } from 'context/drawerContext';
import { UserContext } from 'context/userCodeContext';
import { BrainScratcherDetails } from 'interfaces';
import { useContext, useEffect, useState } from 'react';
import { fetchUserBrainScratchers } from 'services/apiService';
import { openAppOrBrowser } from 'Utils';

const BrainScratchersCard = ({ userId, backendEnv }: { userId: string, backendEnv: string }) => {
    const [brainScratchers, setBrainScratchers] = useState<BrainScratcherDetails[]>([]);
    const { setIsOpenDrawer } = useContext(DrawerContext);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const { user } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const brainData = await fetchUserBrainScratchers(userId ?? "", backendEnv);
                const filteredData = brainData.data.filter(bs => bs.deletedAt === null);
                setBrainScratchers(filteredData);
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoading(false); // Set loading to false when fetch is complete
            }
        };

        fetchData();

    }, [userId, backendEnv]);
    return (
        <div>
            {brainScratchers.length > 0 && (
                <div className={`lg:bg-[rgba(26,29,31,1)] bg-[rgba(16,17,20,1)] block p-5 shadow break-words text-left  rounded-xl my-4`}>
                    <h5 className="lg:text-xl text-lg font-semibold tracking-tight text-white font-sans mb-4">Brain Scratchers</h5>
                    {brainScratchers?.slice(0, 2).map((brainScratcher, index) => (
                        <div key={index} className="my-4">
                            <UserBrainScratcherCard data={brainScratcher} userId={user?.id} />
                        </div>
                    ))}
                    <p className="text-base text-[rgba(96,236,251,1)] underline text-center mt-4 cursor-pointer" onClick={(e) => {
                        if (window.innerWidth >= 1024) {
                            setIsOpenDrawer(true);
                        } else {
                            openAppOrBrowser({ userId: user?.id });
                        }
                    }}>See All Brain Scratchers</p>
                </div>
            )}
        </div>
    )
}

export default BrainScratchersCard;