import placeHolder from "assets/images/bs-user-placeholder.svg";
import React, { useState } from "react";

const BsUserImage = ({ imageUrl, borderRadius, className }: { imageUrl: string, borderRadius: string, className: string }) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div style={{
            borderRadius: borderRadius,
            overflow: 'hidden'
        }} className={className}>
            {isLoading && (
                <img
                    src={placeHolder}
                    alt="Loading Profile"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'fill'
                    }}
                />
            )}
            <img
                src={imageUrl}
                alt="Profile Image"
                onLoad={() => setIsLoading(false)}
                onError={(e) => { e.currentTarget.src = placeHolder; setIsLoading(false); }}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    display: isLoading ? 'none' : 'block'
                }}
            />
        </div>
    )
}

export default BsUserImage