import likeIcon from "assets/images/thumbs-up.svg";
import React from "react";

interface LikeButtonProps {
    likes: number;
}

const LikeButton: React.FC<LikeButtonProps> = ({ likes }) => {
    return (
        <div className='bg-[rgba(16,17,20,1)] lg:w-[7rem] lg:h-[36px] w-[6rem] h-[30px] rounded-3xl text-sm lg:text-base text-white flex items-center justify-center gap-2' style={{ background: 'linear-gradient(105.72deg, #1A1D1F 10.98%, #101114 70.2%)' }}>
            <img src={likeIcon} alt='like' className='w-4 h-4' />
            {likes} Likes
        </div>
    )
}

export default LikeButton