import { getUserMediaInfo } from 'Utils';
import UserCard from 'components/UserCard';
import { Introduction, Participant } from 'interfaces/index'; // Import the Participant and Introduction interfaces
import React, { useRef, useState } from 'react';

interface ParticipantsSectionProps {
    participants: Participant[];
    introductions: Introduction[];
}

const ParticipantsSection: React.FC<ParticipantsSectionProps> = ({ participants, introductions }) => {
    const [activeMedia, setActiveMedia] = useState<string | null>(null);
    const [playingStates, setPlayingStates] = useState<{ [key: string]: boolean }>({});
    const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const pauseAllMedia = () => {
        // Pause any currently playing video
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
            videoRef.current = null;
        }
        // Pause any currently playing audio
        Object.values(audioRefs.current).forEach(audio => {
            if (audio) {
                audio.pause();
                audio = null;
            }
        });
        // Reset playing states
        setPlayingStates({});
    };

    const handleVideoPlay = (introId: string) => {
        pauseAllMedia();
        if (activeMedia && audioRefs.current[activeMedia]) {
            audioRefs.current[activeMedia]?.pause();
            audioRefs.current[activeMedia]!.currentTime = 0;
        }
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
            videoRef.current!.currentTime = 0;
        }
        const newPlayingStates = { ...playingStates, [introId]: true };
        setPlayingStates(newPlayingStates);
        setActiveMedia(introId);
    }

    const handleAudioPlay = (introId: string) => {
        pauseAllMedia();
        setPlayingStates({ [introId]: true });
        if (audioRefs.current[introId]) {
            audioRefs.current[introId]?.play();
        }
    };

    const handlePause = () => {
        pauseAllMedia();
    };

    return (
        <div className='md:mt-24 hidden md:block mb-10'>
            <p className='text-white mb-4 font-bold text-4xl px-24'>Meet the Participants</p>
            <p className='text-gray-500 mb-10 text-2xl'>{participants.length} Participants in Roundtable</p>
            <div className="flex overflow-x-auto space-x-4 px-10">
                {participants.map((participant, index) => {
                    const { intro, isUserPlaying, isAudio } = getUserMediaInfo(participant, introductions, playingStates);
                    
                    return (
                        <UserCard
                            key={index}
                            user={participant}
                            intro={intro}
                            isUserPlaying={isUserPlaying}
                            isAudio={isAudio}
                            onPlay={intro ? (() => isAudio ? handleAudioPlay(intro.introId) : handleVideoPlay(intro.introId)) : () => { }}
                            onPause={handlePause}
                            audioRef={(el: HTMLAudioElement | null) => (audioRefs.current[intro?.introId || ''] = el)}
                            isMobile={true}
                            style={{
                                maxWidth: '250px',
                                minWidth: "250px",
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default ParticipantsSection;




