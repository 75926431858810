/* eslint-disable react/react-in-jsx-scope */
import BsUserImage from 'components/BsUserImage';
import LikeButton from 'components/likeButton';
import ShareButton from 'components/ShareButton';
import { BsContext } from 'context/bsContext';
import { DrawerContext } from 'context/drawerContext';
import { BrainReply as BrainReplyType } from 'interfaces';
import { useContext } from 'react';
import { getRelativeTime, openAppOrBrowser } from 'Utils';

const BrainReply = ({ reply, showArrow = false }: { reply: BrainReplyType, showArrow?: boolean }) => {
    const { bsId } = useContext(BsContext);
    const { setIsOpenDrawer } = useContext(DrawerContext);

    return (
        <div className="flex flex-row gap-4 mt-4 w-full">
            <div className='flex flex-col gap-2 items-center relative'>
                {showArrow ? <div className="absolute top-[0px] left-[-42px] w-7 h-6 border-l-2 border-b-2 border-neutral-700 rounded-bl-full"></div> : ""}
                <div className="min-w-[50px] min-h-[50px] w-[50px] h-[50px] flex-shrink-0">
                    <BsUserImage className='w-full h-full' imageUrl={reply.replier.profile_picture} borderRadius="8px" />
                </div>
            </div>
            <div className="flex flex-row gap-2 w-full">
                <div className="w-full">
                    <div className="w-full h-auto transition-all duration-300 ease-in-out rounded-xl p-[12px] bg-[#101114] bg-gradient-to-bl from-[rgba(99,134,203,0.1)] via-[rgba(238,249,255,0.05)] to-[rgba(96,236,251,0.1)]">
                        <div className="flex flex-row justify-between w-full">
                            <div className='flex flex-col pb-[10px] pr-[10px] w-full'>
                                <div className="text-white lg:text-xl text-base text-left font-medium first-letter:uppercase font-sans break-words line-clamp-1 w-full">
                                    {reply.replier.name}
                                </div>
                                <div className="text-neutral-400 lg:text-base text-sm font-normal text-left font-sans break-words line-clamp-1 w-full">
                                    {reply.replier.headline}
                                </div>
                            </div>
                            <div className='text-neutral-400 lg:text-base text-sm font-normal text-left font-sans whitespace-nowrap'>
                                {getRelativeTime(reply.createdAt)}
                            </div>
                        </div>
                        <div
                            className="text-neutral-300 text-base font-normal text-left font-sans break-words whitespace-normal overflow-hidden w-full"
                            style={{ overflowWrap: 'anywhere' }}
                        >
                            {reply.message}
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 w-full">
                        <div className="my-[10px]" onClick={() => {
                            if (window.innerWidth >= 1024) {
                                setIsOpenDrawer(true);
                            } else {
                                openAppOrBrowser({ bsId: bsId });
                            }
                        }}>
                            <LikeButton likes={reply.likeCount} />
                        </div>
                        <div className="my-[10px]" onClick={() => {
                            if (window.innerWidth >= 1024) {
                                setIsOpenDrawer(true);
                            } else {
                                openAppOrBrowser({ bsId: bsId });
                            }
                        }}>
                            <ShareButton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrainReply
