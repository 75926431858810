import axios from 'axios';
import { BrainReplyResponseType, BrainScratcherData, Data, UserBrainScratcher, UserData } from 'interfaces';

export const fetchRoomData = async (roomId: string, inviteCode: string, backendEnv: string | null): Promise<Data> => {
    try {
        const response = await axios.post<Data>(`${backendEnv === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/room/get-room-messages-participants`, {
            roundtableId: roomId,
            inviteCode: inviteCode,
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchUserData = async (userId: string, backendEnv: string | null): Promise<UserData> => {
    try {
        console.log(userId)
        const response = await axios.get<UserData>(
            `${backendEnv === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/user/profile/${userId}`,
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchBrainScratcherData = async (bsId: string, backendEnv: string | null): Promise<BrainScratcherData> => {
    try {
        const response = await axios.get<BrainScratcherData>(
            `${backendEnv === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/room/public/get-brain-scratcher-by-Id?brainScratcherId=${bsId}`,
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchUserBrainScratchers = async (userId: string, backendEnv: string | null): Promise<UserBrainScratcher> => {
    try {
        const response = await axios.get<UserBrainScratcher>(
            `${backendEnv === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/room/get-brain-scratcher-by-user?userId=${userId}&limit=20&offset=0`,
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}



export const fetchBrainReplyData = async (bsId: string, backendEnv: string | null): Promise<BrainReplyResponseType> => {
    try {
        const response = await axios.get<BrainReplyResponseType>(
            `${backendEnv === "development" ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/room/public/get-brain-replies?replyId=${bsId}`,
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

