/* eslint-disable react/react-in-jsx-scope */

import { DrawerContext } from "context/drawerContext";
import { UserContext } from "context/userCodeContext";
import { useContext } from "react";
import { formatDate, openAppOrBrowser } from "Utils";
import eduPlaceholder from "../../assets/images/edu-placeholder.svg";

const UserEducationCard = () => {
    const { user } = useContext(UserContext);
    const { setIsOpenDrawer } = useContext(DrawerContext);

    console.log(user?.education);
    return (
        <div className={`lg:bg-[rgba(26,29,31,1)] bg-[rgba(16,17,20,1)] block p-5 shadow break-words text-left max-sm:my-8 rounded-xl my-4`}>
            <h5 className="text-xl font-semibold tracking-tight text-white font-sans mb-4">Education</h5>
            {user?.education?.slice(0, 2).map((education) => (
                <div key={education.schoolName} className="flex flex-row gap-3 mt-4">
                    <div>
                        <img src={education.logo ?? eduPlaceholder} alt={education.schoolName ?? education.school} onError={(e) => {
                            e.currentTarget.src = eduPlaceholder;
                        }} />
                    </div>
                    <div>
                        <h6 className="lg:text-xl text-lg font-medium leading-[150%]">{education.schoolName ?? education.school}</h6>
                        <p className="lg:text-base text-sm font-medium leading-[150%]">{education.degreeName ?? education.major}</p>
                        <p className="lg:text-base text-sm font-normal leading-[150%] text-[rgba(173,173,173,1)]">{formatDate(education.start)} - {education.end != null ? formatDate(education.end) : "Present"}</p>
                    </div>
                </div>
            ))}
            {user?.education?.length && user?.education?.length > 2 ? <p className="text-base text-[rgba(96,236,251,1)] underline text-center mt-4" onClick={() => {
                if (window.innerWidth >= 1024) {
                    setIsOpenDrawer(true);
                } else {
                    openAppOrBrowser({ userId: user?.id });
                }
            }}>See All Education</p> : ""}
        </div>
    )
}

export default UserEducationCard