/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import BrainReply from 'components/BrainReply';
import BsUserImage from 'components/BsUserImage';
import LikeButton from 'components/likeButton';
import ShareButton from 'components/ShareButton';
import { BsContext } from 'context/bsContext';
import { DrawerContext } from 'context/drawerContext';
import { BrainReplyResponseType, BrainReply as BrainReplyType } from 'interfaces';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchBrainReplyData } from 'services/apiService';
import { getRelativeTime, openAppOrBrowser } from 'Utils';


const BrainResponse = ({ reply, showArrow = false }: { reply: BrainReplyType, showArrow?: boolean }) => {
    const [data, setData] = useState<BrainReplyResponseType | null>(null);
    const [showAllReplies, setShowAllReplies] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { isOpenDrawer, setIsOpenDrawer } = useContext(DrawerContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const backendEnv = queryParams.get('env') || null;
    const [lastReplyHeight, setLastReplyHeight] = useState<number | null>(null);
    const lastReplyRef = useRef<HTMLDivElement | null>(null);
    const { bsId } = useContext(BsContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!showArrow) {
                    const bsData = await fetchBrainReplyData(reply.id ?? "", backendEnv);
                    setData(bsData);
                }
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoading(false); // Set loading to false when fetch is complete
            }
        };

        fetchData();
    }, [reply, backendEnv]);

    useEffect(() => {
        if (lastReplyRef.current) {
            setLastReplyHeight(lastReplyRef.current.offsetHeight);
        }
    }, [data, showAllReplies]);

    if (loading) {
        return (
            <div className="w-full p-4 animate-pulse">
                <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-neutral-800 rounded-lg"></div>
                    <div className="flex-1">
                        <div className="h-4 bg-neutral-800 rounded w-3/4 mb-2"></div>
                        <div className="h-4 bg-neutral-800 rounded w-1/2"></div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="h-20 bg-neutral-800 rounded"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row gap-4 mt-4 mr-2">
            <div className='flex flex-col gap-2 items-center relative'>
                {showArrow ? <div className="absolute top-[0px] left-[-42px] w-7 h-6 border-l-2 border-b-2 border-neutral-700 rounded-bl-full"></div> : ""}
                <div className="min-w-[50px] min-h-[50px] w-[50px] h-[50px] flex-shrink-0">
                    <BsUserImage className='w-full h-full' imageUrl={reply.replier.profile_picture} borderRadius="8px" />
                </div>
                {data?.data.length ? data.data.length > 1 && (
                    <div className={`w-[2px] bg-neutral-700 h-full ${!showAllReplies ? "mb-8" : `lg:mb-40 md:mb-30`} rounded-full`} style={{ marginBottom: lastReplyHeight ? `${lastReplyHeight}px` : undefined }}></div>
                ) : ""}
            </div>
            <div className="flex flex-row gap-2">
                <div>
                    <div className="w-full lg:w-full h-auto transition-all duration-300 ease-in-out rounded-xl p-[12px] bg-[#101114] bg-gradient-to-bl from-[rgba(99,134,203,0.1)] via-[rgba(238,249,255,0.05)] to-[rgba(96,236,251,0.1)] overflow-hidden">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col pb-[10px] pr-[10px]">
                                <div className="text-white lg:text-xl text-base text-left font-medium first-letter:uppercase font-sans break-words line-clamp-1">
                                    {reply.replier.name}
                                </div>
                                <div className="text-neutral-400 lg:text-base text-sm font-normal text-left font-sans break-words line-clamp-1 ">
                                    {reply.replier.headline}
                                </div>
                            </div>
                            <div className="text-neutral-400 lg:text-base text-sm font-normal text-left font-sans">
                                {getRelativeTime(reply.createdAt)}
                            </div>
                        </div>
                        <div
                            className="text-neutral-300 text-base font-normal text-left font-sans break-words whitespace-normal overflow-hidden"
                            style={{ overflowWrap: 'anywhere' }}
                        >
                            {reply.message}
                        </div>
                    </div>

                    <div className="flex flex-row gap-2">
                        <div className="my-[10px]" onClick={() => {
                            if (window.innerWidth >= 1024) {
                                setIsOpenDrawer(true);
                            } else {
                                openAppOrBrowser({ bsId: bsId });
                            }
                        }}>
                            <LikeButton likes={reply.likeCount} />
                        </div>
                        <div className="my-[10px]" onClick={() => {
                            if (window.innerWidth >= 1024) {
                                setIsOpenDrawer(true);
                            } else {
                                openAppOrBrowser({ bsId: bsId });
                            }
                        }}>
                            <ShareButton />
                        </div>
                    </div>
                    {data?.data.slice(0, showAllReplies ? data.data.length : 1).map((reply, index) => (
                        <div key={reply.id} >
                            <div ref={index === data.data.length - 1 ? lastReplyRef : null} >
                                <BrainReply reply={reply} showArrow={data?.data.length ? data.data.length > 1 : false} />
                            </div>
                            {data?.data.length ? data.data.length > 1 && (
                                <div className="flex flex-row items-center relative">
                                    {!showAllReplies ? <div className="absolute top-[-10px] left-[-42px] w-7 h-6 border-l-2 border-b-2 border-neutral-700 rounded-bl-full"></div> : ""}
                                    <button
                                        onClick={() => setShowAllReplies(!showAllReplies)}
                                        className="text-neutral-300 text-left font-sans underline w-full"
                                    >
                                        {showAllReplies ? "" : `View ${data?.data.length - 1} more replies`}
                                    </button>
                                </div>
                            ) : ""}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BrainResponse