/* eslint-disable react/react-in-jsx-scope */
import FacebookIcon from "assets/images/facebook_icon.svg";
import GitHubIcon from "assets/images/github_icon.svg";
import InstagramIcon from "assets/images/insta_icon.svg";
import LinkedInIcon from "assets/images/linkedin_icon.svg";
import PlayIcon from "assets/images/play-icon.png";
import PortfolioIcon from "assets/images/portfolio_icon.svg";
import placeHolder from "assets/images/user-placeholder.svg";
import TwitterIcon from "assets/images/x_icon.svg";
import { DrawerContext } from "context/drawerContext";
import { UserContext } from "context/userCodeContext";
import { useContext, useEffect, useRef, useState } from "react";
import { openAppOrBrowser } from "Utils";

export function UserInfoCard() {
    const { user } = useContext(UserContext);
    const { setIsOpenDrawer } = useContext(DrawerContext);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const imageRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        const imgElement = imageRef.current;

        if (imgElement) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setIsImageLoaded(true);
                        observer.disconnect();
                    }
                },
                {
                    rootMargin: "100px",
                }
            );

            observer.observe(imgElement);

            return () => {
                if (imgElement) {
                    observer.unobserve(imgElement);
                }
            };
        }
    }, []);

    return (
        <div className="flex flex-col sm:w-full lg:w-auto">
            <div className="flex flex-row lg:flex-col items-start justify-start">
                <div className="relative mb-5">
                    <img
                        ref={imageRef}
                        className={`h-[140px] w-[140px] min-w-[140px] lg:h-[332px] lg:w-[332px] lg:min-w-[332px] rounded-2xl ${user?.profile_picture ? "object-cover" : "object-fit"} object-center`}
                        data-src={user?.profile_picture}
                        style={{
                            boxShadow: "0 0px 10px rgba(96, 236, 251, 0.2)",
                            aspectRatio: "1",
                        }}
                        src={isImageLoaded ? (user?.profile_picture || placeHolder) : placeHolder}
                        alt="nature image"
                        loading="lazy"
                    />
                    <div className="absolute bottom-0 left-0 right-0 lg:h-20 h-10 bg-black/30 backdrop-blur-lg rounded-b-2xl flex items-center justify-center">
                        <div className="flex flex-row items-center lg:gap-2 gap-1" onClick={() => {
                            if (window.innerWidth >= 1024) {
                                setIsOpenDrawer(true);
                            } else {
                                openAppOrBrowser({ userId: user?.id });
                            }
                        }}>
                            <img
                                src={PlayIcon}
                                alt="Play Icon"
                                className="lg:w-12 lg:h-12 w-8 h-8"
                            />
                            <span className="text-white text-[16px] lg:text-[30px] leading-[150%] font-normal">Play intro</span>
                        </div>
                    </div>
                </div>
                <div className="lg:w-[332px] lg:min-w-[332px] lg:bg-[rgba(26,29,31,1)] rounded-xl items-start justify-start text-left lg:p-5 pl-3 mb-3">
                    <div className="text-white lg:text-[30px] text-[24px] leading-[150%] font-semibold break-words">
                        {user?.name}
                    </div>
                    <div className="text-[rgba(220,223,223,1)] lg:text-[20px] text-[16px] leading-[150%] font-normal tracking-[0%] break-words">
                        {user?.headline ? (
                            user.headline.length > 100 ?
                                `${user.headline.substring(0, 100)}...` :
                                user.headline
                        ) : ''}
                    </div>
                </div>
            </div>




            {(user?.facebook || user?.linkedin || user?.instagram || user?.github || user?.twitter || user?.portfolio) && (
                <div className="lg:w-[332px] lg:min-w-[332px] lg:bg-[rgba(26,29,31,1)] rounded-xl items-start justify-start text-left lg:py-5 py-2 px-16">
                    <div className="flex flex-row items-center justify-center gap-4 align-middle">
                        {user?.facebook && (
                            <a href={user?.facebook} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
                                <img src={FacebookIcon} alt="Facebook" />
                                <span className="text-sm text-gray-300 mt-1">Facebook</span>
                            </a>
                        )}
                        {user?.linkedin && (
                            <a href={user?.linkedin} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
                                <img src={LinkedInIcon} alt="LinkedIn" />
                            </a>
                        )}
                        {user?.instagram && (
                            <a href={user?.instagram} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
                                <img src={InstagramIcon} alt="Instagram" />
                            </a>
                        )}
                        {user?.github && (
                            <a href={user?.github} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
                                <img src={GitHubIcon} alt="GitHub" />
                            </a>
                        )}
                        {user?.twitter && (
                            <a href={user?.twitter} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
                                <img src={TwitterIcon} alt="Twitter" />
                            </a>
                        )}
                        {user?.portfolio && (
                            <a href={user?.portfolio} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center">
                                <img src={PortfolioIcon} alt="Portfolio" />
                            </a>
                        )}
                    </div>
                </div>
            )}
            <div
                className="lg:hidden block min-w-full h-[1px] my-3 align-middle items-center"
                style={{
                    border: "1px solid",
                    borderImageSource: "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.28) 54.42%, rgba(255, 255, 255, 0) 100%)",
                    borderImageSlice: "1"
                }}
            />
        </div>
    );
}
