import React, { ReactNode, createContext, useState } from 'react';

interface DrawerContextProps {
    isOpenDrawer: boolean;
    setIsOpenDrawer: (isOpen: boolean) => void;
}

export const DrawerContext = createContext<DrawerContextProps>({
    isOpenDrawer: false,
    setIsOpenDrawer: () => { }
});

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

    return (
        <DrawerContext.Provider value={{ isOpenDrawer, setIsOpenDrawer }}>
            {children}
        </DrawerContext.Provider>
    );
};
