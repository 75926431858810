import { Introduction, Participant, RoundtableUser } from "interfaces";

export const openAppOrBrowser = async ({
    inviteCode,
    roomId,
    userId,
    bsId,
}: {
    inviteCode?: string;
    roomId?: string;
    userId?: string;
    bsId?: string;
}) => {
    // const deeplinkUrl = `${process.env.REACT_APP_SCHEMA}invited=${roomId}`;
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (userId) {
        await navigator.clipboard
            .writeText(`userId:${userId}`)
    }
    else if (roomId) {
        await navigator.clipboard
            .writeText(`invited:${roomId}`)
    }
    else if (inviteCode) {
        await navigator.clipboard
            .writeText(`inviteCode:${inviteCode}`)
    } else if (bsId) {
        await navigator.clipboard
            .writeText(`bsId:${bsId}`)
    }


    if (isAndroid) {
        window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
    } else {
        window.location.href = process.env.REACT_APP_STORE_URL ?? "";
    }
}

export const getUserMediaInfo = (user: RoundtableUser, introductions: Introduction[], playingStates: {
    [key: string]: boolean;
}) => {
    const intro = user.UserRoundtableStatuses?.introId
        ? introductions.find(intro => intro.introId === user.UserRoundtableStatuses.introId)
        : null;

    const isUserPlaying = playingStates[intro?.introId || ''] ?? false;
    const isAudio = intro && intro.type === 'audio' ? true : false;

    return { intro, isUserPlaying, isAudio };
};


export const goToStore = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isAndroid) {
        window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
    } else {
        window.location.href = process.env.REACT_APP_STORE_URL ?? "";
    }
}

export const replaceUuidsWithUsernames = (message: string, users: any) => {
    let updatedMessage = message;

    users.forEach((user: Participant) => {
        const {
            name,
            UserRoundtableStatuses } = user;
        const regex = new RegExp(`@${UserRoundtableStatuses.participantId}`, 'g');
        updatedMessage = updatedMessage.replace(regex, `@${name}`);
    });
    return updatedMessage;
};

export const getRelativeTime = (timestamp: string): string => {
    const now = new Date();
    const date = new Date(timestamp);
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    const intervals: { [key: string]: number } = {
        y: 31536000,
        w: 604800,
        d: 86400,
        h: 3600,
        m: 60,
    };

    for (const key in intervals) {
        const interval = intervals[key];
        const count = Math.floor(seconds / interval);
        if (count >= 1) {
            return `${count}${key}`;
        }
    }
    return "now";
};

export const openAppStore = () => {
    window.location.href = process.env.REACT_APP_STORE_URL ?? "";
}

export const openPlayStore = () => {
    window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? "";
}

export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
}