/* eslint-disable react/react-in-jsx-scope */
import { UserContext } from "context/userCodeContext";
import { useContext } from "react";


const UserSummaryCard = () => {
    const { user } = useContext(UserContext);

    return (
        <div className={`lg:bg-[rgba(26,29,31,1)] bg-[rgba(16,17,20,1)] block p-5 shadow break-words text-left  rounded-xl`}>
            <h5 className="lg:text-xl text-lg font-semibold tracking-tight text-white font-sans mb-4">About</h5>
            <p className={`lg:text-lg text-base font-normal text-gray-700 ${user?.summary ? "text-neutral-200" : "text-neutral-500"} font-sans leading-relaxed`}>{user?.summary ? user?.summary : "No Summary"}</p>
        </div>
    )
}

export default UserSummaryCard