import { openAppOrBrowser, openAppStore, openPlayStore } from 'Utils';
import AppStoreBtn from "assets/images/app-store-btn.svg";
import ArrowRight from "assets/images/arrow-up-right.svg";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";
import NavBarButton from 'components/NavBarButton';
import PrimaryButton from 'components/PrimaryButton';
import { InviteContext } from 'context/inviteCodeContext';
import { NavBarProps } from 'interfaces';
import React, { useContext, useEffect, useState } from 'react';
import appLogo from "../../assets/images/logo.png";

const Navbar: React.FC<NavBarProps> = ({ roomId, userId, bsId }) => {
    const [scrolled, setScrolled] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const { inviteCode } = useContext(InviteContext);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 10);
        };

        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    return (
        <nav className={`md:stick border-b border-neutral-800 top-0 left-0 w-full flex justify-between items-center px-4 py-1 z-20 transition-colors duration-300 ${scrolled ? 'bg-opacity-50 backdrop-blur-lg bg-black' : 'bg-transparent'}`}>
            <div className="flex items-center">
                <img src={appLogo} alt="App Logo" className="h-20 w-20" />
                <span className="text-white text-base font-bold md:text-xl text-left">QLU Roundtables</span>
            </div>
            {isDesktop ? (
                <div className="flex items-center gap-4">
                    <img src={AppStoreBtn} alt="App Store" className="h-12 w-auto cursor-pointer" onClick={() => openAppStore()} />
                    <img src={GoogleStoreBtn} alt="Google Play Store" className="h-12 w-auto cursor-pointer" onClick={() => openPlayStore()} />
                </div>
            ) : (
                <>
                    <div className='md:hidden border border-white rounded-full'>
                        <div className="flex items-center mx-4 ">
                            <button className="text-white text-base py-2 md:text-lg mr-2" onClick={() => openAppOrBrowser({ inviteCode, roomId, userId, bsId })}>
                                Get App
                            </button>
                            <img src={ArrowRight} />
                        </div>
                    </div>
                    <div className='md:flex hidden  md:items-center md:mx-4'>
                        <div className='mt-4 mb-5 md:mt-5 px-2'>
                            {userId || bsId ? <NavBarButton onClick={() => { openAppOrBrowser({ inviteCode, roomId, userId, bsId }) }} title={"Get App"} /> : <PrimaryButton onClick={() => { openAppOrBrowser({ inviteCode, roomId, userId, bsId }) }} title={"Join Roundtable"} />}
                        </div>
                    </div>
                </>
            )}
        </nav>
    );
}

export default Navbar;


