/* eslint-disable react/react-in-jsx-scope */

import { DrawerContext } from "context/drawerContext";
import { UserContext } from "context/userCodeContext";
import { useContext } from "react";
import { formatDate, openAppOrBrowser } from "Utils";
import expPlaceholder from "../../assets/images/exp-placeholder.svg";

const UserExperienceCard = () => {
    const { user, } = useContext(UserContext);
    const { setIsOpenDrawer } = useContext(DrawerContext);
    console.log(user?.experience);
    return (
        <div className={`lg:bg-[rgba(26,29,31,1)] bg-[rgba(16,17,20,1)] block p-5 shadow break-words text-left max-sm:my-8 rounded-xl my-4`}>
            <h5 className="lg:text-xl text-lg font-semibold tracking-tight text-white font-sans mb-4">Experience</h5>
            {user?.experience?.slice(0, 2).map((experience) => (
                <div key={experience.title} className="flex flex-row gap-3 mt-4">
                    <div>
                        <img src={experience.logo || expPlaceholder} alt={experience.company_name ?? experience.companyName} onError={(e) => {
                            e.currentTarget.src = expPlaceholder;
                        }} />
                    </div>
                    <div>
                        <h6 className="lg:text-xl text-lg font-medium leading-[150%]">{experience.title}</h6>
                        <p className="lg:text-base text-sm font-medium leading-[150%]">{experience.company_name ?? experience.companyName}</p>
                        <p className="lg:text-base text-sm font-normal leading-[150%] text-[rgba(173,173,173,1)]">{formatDate(experience.start)} - {experience.end != null ? formatDate(experience.end) : "Present"}</p>
                    </div>
                </div>
            ))}
            {user?.experience?.length && user?.experience?.length > 2 ? <p className="text-base text-[rgba(96,236,251,1)] underline text-center mt-4" onClick={() => {
                if (window.innerWidth >= 1024) {
                    setIsOpenDrawer(true);
                } else {
                    openAppOrBrowser({ userId: user?.id });
                }
            }}>See All Experiences</p> : ""}
        </div>
    )
}

export default UserExperienceCard