/* eslint-disable react/react-in-jsx-scope */
import { BsProvider } from 'context/bsContext';
import { DrawerProvider } from 'context/drawerContext';
import { InviteProvider } from 'context/inviteCodeContext';
import { UserProvider } from 'context/userCodeContext';
import BrainScratcherPage from 'pages/BrainScratcherPage';
import UserPage from 'pages/UserPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';

function App() {
  return (
    <div className='App'>
      <BsProvider>
        <InviteProvider>
          <UserProvider>
            <DrawerProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/:inviteCodeParam" element={<HomePage />} />
                  <Route path="/u/:userId" element={<UserPage />} />
                  <Route path="/bs/:brainScratcherId" element={<BrainScratcherPage />} />
                </Routes>
              </BrowserRouter>
            </DrawerProvider>
          </UserProvider>
        </InviteProvider>
      </BsProvider>
    </div >
  );
}

export default App;
