/* eslint-disable @typescript-eslint/no-unused-vars */

import AppStoreBtn from "assets/images/app-store-btn.svg";
import BrainScratcherNotFound from "assets/images/brain-scratcher-not-found.png";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";

import BottomDrawer from "components/BottomDrawer";
import BrainResponse from "components/BrainResponse";
import BrainScratcherCard from "components/BrainScatcherCard";
import Navbar from 'components/Navbar';
import { BsContext } from "context/bsContext";
import { DrawerContext } from "context/drawerContext";
import { BrainScratcherData } from "interfaces";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { fetchBrainScratcherData } from "services/apiService";
import { openAppOrBrowser } from "Utils";
import styles from './BrainScratcherPage.module.css';

const BrainScratcherPage: React.FC = () => {
    const [data, setData] = useState<BrainScratcherData | null>(null);
    const [loading, setLoading] = useState(true);
    const [isRepliesEmpty, setIsRepliesEmpty] = useState(false);
    const [error, setError] = useState("");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const backendEnv = queryParams.get('env') || null;
    const { brainScratcherId } = useParams();
    const { bsId, setBsId } = useContext(BsContext);
    const { setIsOpenDrawer } = useContext(DrawerContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const bsData = await fetchBrainScratcherData(brainScratcherId ?? "", backendEnv);
                setBsId(bsData.data.id);
                setData(bsData);
                setIsRepliesEmpty(bsData.data.replies.length === 0);
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoading(false); // Set loading to false when fetch is complete
            }
        };

        fetchData();
    }, [brainScratcherId, backendEnv, bsId,]);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-r-2 border-cyan-300"></div>
            </div>
        );
    }

    if (data == null) {
        return (
            <div>
                <Navbar bsId={bsId} />
                <div className="flex flex-col items-center justify-center h-screen text-center">
                    <img src={BrainScratcherNotFound} alt="Brain Scratcher Not Found" className="mb-3 w-48" />
                    <h1 className="text-2xl font-bold mb-2 text-neutral-100 px-20">Brain Scratcher no longer exists.</h1>
                </div>
                <div className={`bg-[#202223] w-full py-12 lg:py-20 ${styles['footer']} backdrop-blur-md mt-8`}>
                    <div className="hidden md:block">{/* Empty first column on larger screens */}</div>
                    <div className="flex flex-col items-center justify-center text-white space-y-4">
                        <p className='font-sans text-lg text-center px-10  lg:px-20 font-normal'>
                            Download the Roundtables app, complete your profile & connect.
                        </p>
                        <div className="flex flex-row justify-center gap-2">
                            <img src={AppStoreBtn} alt="App Store Logo" className="w-32 lg:w-44" onClick={() => { window.location.href = process.env.REACT_APP_STORE_URL ?? ""; }} />
                            <img src={GoogleStoreBtn} alt="Google Play Logo" className="w-36 lg:w-48" onClick={() => { window.location.href = process.env.REACT_APP_PLAY_STORE_URL ?? ""; }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`${styles['main-content']}`}>
            <Navbar bsId={data.data.id} />
            <div className={`flex gap-[20px] flex-row md:flex-col ${isRepliesEmpty ? 'lg:flex-col lg:items-center md:flex-col flex-col' : 'lg:flex-row md:flex-col flex-col justify-start'} lg:px-24 px-4 max-[380px]:px-[0.5rem] mt-[28px] mb-[28px]`}>
                <BrainScratcherCard
                    data={data.data}
                    bsId={bsId}
                />
                <button onClick={() => openAppOrBrowser({ bsId: bsId })} className={`${isRepliesEmpty ? 'lg:block lg:w-[60%] w-full' : 'lg:hidden'} block bg-[rgba(8,17,18,1)] text-white lg:py-5 py-3 rounded-lg border lg:text-xl text-lg font-medium `} style={{ borderColor: 'rgba(96, 236, 251, 1)', boxShadow: 'inset 0 0 10px rgba(96, 236, 251, 0.5)' }}>
                    Join the conversation
                </button>

                {!isRepliesEmpty && (
                    <div className='w-full lg:w-[40%] text-white min-h-[200px] h-auto flex-grow rounded-lg transition-all duration-300 ease-in-out'>
                        <div className="flex flex-col">
                            <h1 className="lg:block hidden text-2xl font-bold text-left mb-2">Responses</h1>
                            <div className="lg:max-h-[500px] lg:overflow-y-auto overflow-x-hidden">
                                {data.data.replies.map((reply, index) => (
                                    <div key={index} className={index === data.data.replies.length - 1 ? "mb-10" : ""}>
                                        <BrainResponse reply={reply} />
                                    </div>
                                ))}
                            </div>
                            <div className="hidden lg:block sticky bottom-0 px-4 shadow-[rgba(0,_0,_0,_1)_0px_-40px_70px_4px]">
                                <button onClick={() => {
                                    if (window.innerWidth >= 1024) {
                                        setIsOpenDrawer(true);
                                    } else {
                                        openAppOrBrowser({ bsId: bsId });
                                    }
                                }} className="w-full py-4 bg-[rgba(8,17,18,1)] text-white px-4 rounded-lg border  text-xl font-medium" style={{ borderColor: 'rgba(96, 236, 251, 1)', boxShadow: 'inset 0 0 10px rgba(96, 236, 251, 0.5)' }}>
                                    Join the conversation
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <BottomDrawer />
        </div>
    )
}

export default BrainScratcherPage;