/* eslint-disable react/react-in-jsx-scope */
import AppStoreBtn from "assets/images/app-store-btn.svg";
import arrowIcon from "assets/images/arrow.svg";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";
import { DrawerContext } from "context/drawerContext";
import { useContext, useEffect, useState } from 'react';
import { openAppStore, openPlayStore } from "Utils";

const BottomDrawer = () => {
    const { isOpenDrawer, setIsOpenDrawer } = useContext(DrawerContext);
    const [isOpen, setIsOpen] = useState(isOpenDrawer ?? false);

    useEffect(() => {
        setIsOpen(isOpenDrawer ?? false);
    }, [isOpenDrawer]);

    const toggleDrawer = () => {
        setIsOpen((prev) => !prev);
        setIsOpenDrawer(!isOpen);
    };

    return (
        <>
            <div
                className={`rounded-t-lg fixed bottom-0 left-0 w-full bg-[rgba(51,53,56,0.4)] backdrop-blur-lg text-white p-4 shadow-lg transition-transform duration-300 ${isOpen ? 'translate-y-0' : 'translate-y-[85%]'} lg:block hidden`}
            >
                <div className="flex items-center justify-center">
                    <div className="flex items-center gap-4">
                        <img src={AppStoreBtn} alt="App Store" className="h-12 w-auto cursor-pointer" onClick={() => openAppStore()} />
                        <img src={GoogleStoreBtn} alt="Google Play Store" className="h-12 w-auto cursor-pointer" onClick={() => openPlayStore()} />
                    </div>
                </div>
                <div className="absolute -top-[48px] right-7 bg-[rgba(51,53,56,0.4)] w-12 h-15 p-2 rounded-t-lg">
                    <button
                        onClick={toggleDrawer}
                        className=" bg-[rgba(38,41,43,1)] h-full w-full px-2 py-3 rounded-lg"
                    >
                        {isOpen ? <img src={arrowIcon} alt="arrow" /> : <img src={arrowIcon} alt="arrow" className="rotate-180" />}
                    </button>
                </div>

            </div>
        </>
    );
};

export default BottomDrawer;

// Now, the arrow button is part of the drawer itself! When open, it appears at the top right. When closed, it stays at the bottom. Let me know if you want any adjustments! 🚀
